import React from "react";
import { Route, Routes } from "react-router-dom";
import Pdf from "./pages/Pdf";
import Home from "./pages/Home";
import Adminside from "./pages/Adminside";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Adminside />} />
      <Route path="/pdf/*" element={<Pdf />} />
    </Routes>
  );
};

export default App;
