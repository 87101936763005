import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import "./Header.scss";
import autoAnimate from "@formkit/auto-animate";
import Logo from "../../assets/icons/logo.png";

const Header: React.FC = () => {
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div className="header-wrapper">
      <div className="mx">
        <div className="header-container">
          <div className="logo-img">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
