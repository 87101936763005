import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import "./PdfCard.scss";
import PdfCard from "./pdfContainer";
import { db } from "../../../utils/firebase";
import MOdalPopUp from "../../../components/ModalPopupBox";
import { collection, getDocs, query } from "firebase/firestore/lite";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";

export interface IData {
  title: string;
  component: string;
  index: number;
  subIndex: number;
  id: string;
}

const PdfContainer: React.FC = () => {
  const [modal, setModal] = useState(true);
  const [data, setData] = useState<IData[]>([]);

  const handleGetData = useCallback(async () => {
    try {
      const q = query(collection(db, "PDF"));
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IData, "id">),
      }));
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const sortedData = data.sort((a, b) => {
    if (a.index !== b.index) {
      return a.index - b.index;
    }
    return a.subIndex - b.subIndex;
  });

  return (
    <>
      <div className="pdfcard-container">
        <Link to="/">
          <div className="border" onClick={() => setModal(false)}>
            <div className="backgrund-icon">
              <Plus />
            </div>
            <div className="pdfcard-createcontainer-para">
              <div className="building-circle flex-content"></div>
              <p>Create PDF</p>
            </div>
          </div>
        </Link>
        {modal ? "" : <MOdalPopUp setModal={setModal} />}
      </div>
      <div className="pdfcard-container">
        {sortedData.map((f, i) => (
          <PdfCard key={i} {...f} />
        ))}
      </div>
    </>
  );
};

export default PdfContainer;
