import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import autoAnimate from "@formkit/auto-animate";
import { collection, getDocs, query } from "firebase/firestore/lite";
import "./Sidebar.scss";
import { db } from "../../utils/firebase";
import { IData } from "../../pages/Home/component";
import TopChevronDown from "../../assets/icons/top-chevron-down.svg";
import PrintingImage from "../../assets/images/priniting-image.png";
import { ReactComponent as Building } from "../../assets/icons/building.svg";

const Sidebar: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState<IData[]>([]);

  const handleGetData = useCallback(async () => {
    try {
      const q = query(collection(db, "PDF"));
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IData, "id">),
      }));
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  //auto animate
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div className="sidebar-wrapper">
      <div className="drop-down">
        <div
          className={`sidebar-flex-container flex-content ${
            isActive ? "active" : ""
          }`}
          onClick={handleToggle}
        >
          <div className="building">
            <Building />

            <p>Safetyfile</p>
          </div>
          <img
            src={TopChevronDown}
            alt=""
            className={`drop-down-icon ${isActive ? "rotate" : ""}`}
          />
        </div>
      </div>
      <div className="Printing-Img">
        <img src={PrintingImage} alt="priniting" />
      </div>
      <div ref={parent}>
        {isActive && (
          <div className="company-name">
            <div className="company">
              {data.map((f, i) => (
                <Link to={`pdf/${f.title}`}>
                  <p>{f.title}</p>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
