import React from "react";
import * as Yup from "yup";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { addDoc, collection } from "firebase/firestore/lite";
import "./ModalPopupBox.scss";
import Button from "../Button";
import { db } from "../../utils/firebase";

const sectionOptions = [
  { label: "Option 1", value: "Index" },
  { label: "Option 2", value: "Letter of Good Standing" },
  { label: "Option 3", value: "Site Rules & Policies" },
  { label: "Option 4", value: "Management Plans" },
  {
    label: "Option 5",
    value: "Emergency Evacuation Plan & Incident Reporting",
  },
  { label: "Option 6", value: "Organogram & Appointments with Competencies" },
  { label: "Option 7", value: "Mandatary Agreement, Contractor Appointment" },
  {
    label: "Option 8",
    value: "Client Specification/Notice of Construction/Work Permit",
  },
  { label: "Option 9", value: "Method Statement/Risk Assessment Review Plan" },
  { label: "Option 10", value: "Risk Assessments with Safe Work Procedures" },
  { label: "Option 11", value: "DSTI's / PTO" },
  { label: "Option 12", value: "Audits" },
  { label: "Option 13", value: "Safety Meetings" },
  { label: "Option 14", value: "Lock Out" },
  { label: "Option 15", value: "Inductions/Next of Kin/ID/Medicals" },
  { label: "Option 16", value: "Disiplinary Action/Warning" },
  { label: "Option 17", value: "Copy of the Act" },
  // Add more options as needed
];

const initialvalue = {
  title: "",
  index: 0,
  subIndex: 0,
  component: "",
};
interface IAdminside {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(100, "companyname  must be at most 100 characters")
    .required("title  is required"),
  index: Yup.number().required("index is required"),
  subIndex: Yup.number(),
});
const MOdalPopUp: React.FC<IAdminside> = ({ setModal }) => {
  const handleSubmit = async (values: typeof initialvalue) => {
    try {
      const docRef = await addDoc(collection(db, "PDF"), {
        ...values,
      });
      console.log(docRef);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setModal(true);
    }
  };

  return (
    <div className="modal">
      <div className="modal-overlay" onClick={() => setModal(true)} />
      <Formik
        initialValues={initialvalue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="modal-box" onChange={() => setModal(false)}>
              <div className="custom-name">
                <Field
                  name="title"
                  type="text"
                  placeholder="Enter your title"
                />
                <label htmlFor="section">Section:</label>
                <Field as="select" id="section" name="section">
                  <option value="">Select an option</option>
                  {sectionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label} - {option.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="title"
                  component="div"
                  className="errorMsg"
                />
              </div>
              <div className="custom-name">
                <Field
                  name="index"
                  type="number"
                  placeholder="Enter your index"
                />
                <ErrorMessage
                  name="index"
                  component="div"
                  className="errorMsg"
                />
              </div>
              <div className="custom-name">
                <Field
                  name="subIndex"
                  type="number"
                  placeholder="Enter your sub-index"
                />
                <ErrorMessage
                  name="subIndex"
                  component="div"
                  className="errorMsg"
                />
              </div>
              <div>
                <Button varient="primary" disabled={isSubmitting}>
                  {isSubmitting ? "Loading" : "Submit"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MOdalPopUp;
