import { Link } from "react-router-dom";
import autoAnimate from "@formkit/auto-animate";
import { AnimatePresence, motion } from "framer-motion";
import { collection, getDocs, query } from "firebase/firestore/lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MobileHeader.scss";
import { db } from "../../utils/firebase";
import { IData } from "../../pages/Home/component";
import Menu from "../../assets/icons/menu.png";
import Logo from "../../assets/icons/logo.png";
import Close from "../../assets/icons/close.svg";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import { ReactComponent as TopChevronDown } from "../../assets/icons/top-chevron-down.svg";

const MoblieHeader: React.FC = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  const [data, setData] = useState<IData[]>([]);

  const handleGetData = useCallback(async () => {
    try {
      const q = query(collection(db, "PDF"));
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IData, "id">),
      }));
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  //auto animate
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleOpenToggle = () => {
    setOpenToggle(!openToggle);
  };

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  return (
    <div className="company-header">
      <div className="media-header">
        <Link to="/">
          <div className="media-logo">
            <img src={Logo} alt="" />
          </div>
        </Link>
        <div onClick={() => setOpenClose((m) => !m)} className="menu">
          <img src={Menu} alt="" />
        </div>
      </div>

      <AnimatePresence>
        {openClose && (
          <motion.div
            className="sidebar-backdrop"
            onClick={() => setOpenClose(false)}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <motion.div
              className="bar"
              onClick={(e: any) => e.stopPropagation()}
              animate={{ right: 0, transitionDelay: "-200ms" }}
              exit={{ right: -300 }}
              initial={{ right: -300 }}
            >
              <div className="header-side-bar">
                <div className="close-icon" onClick={() => setOpenClose(false)}>
                  <img src={Close} alt="" />
                </div>

                <div className="border"></div>

                <div
                  className={`company-drop-down-content  ${
                    openToggle ? "active" : ""
                  }`}
                  onClick={handleOpenToggle}
                >
                  <div className="media-building">
                    <div>
                      <Building />
                    </div>

                    <p>Safetyfile</p>
                  </div>
                  <div>
                    <TopChevronDown
                      className={`drop-down-icon ${openToggle ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                <div ref={parent}>
                  {openToggle && (
                    <div className="company-name">
                      {data.map((f, i) => (
                        <Link to={`pdf/${f.title}`}>
                          <p>{f.title}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MoblieHeader;
