import React from "react";
import { Link } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore/lite";
import { IData } from "..";
import { db } from "../../../../utils/firebase";
//import { ReactComponent as Pdf } from "../../../../assets/icons/pdf.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";

const PdfCard: React.FC<IData> = ({ id, title, index, subIndex }) => {
  const handleDelete = async () => {
    try {
      const docRef = doc(db, "PDF", id);
      await deleteDoc(docRef);
      console.log("Document deleted successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
  return (
    <div className="border">
      {/* <Link to={`pdf/${title}`}>
        <div className="backgrund-icon">
          <Pdf />
        </div>
      </Link> */}
      <div className="pdfcard-container-para">
        <Link to={`pdf/${title}`}>
          <div>
            <p>
              {index}.{subIndex} {title}
            </p>
          </div>
        </Link>
        <div className="delete-icon" onClick={handleDelete}>
          <Delete />
        </div>
      </div>
    </div>
  );
};

export default PdfCard;
