import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore/lite";
import "./Adminside.scss";
import { db } from "../../utils/firebase";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import axios from "axios";
// import { highlight, languages } from "prismjs";
// import "prismjs/components/prism-jsx.min";
// import "prismjs/themes/prism.css";
//import Editor from "react-simple-code-editor";
import Editor from '@monaco-editor/react';

interface IAdminside {
  component: string;
  title: string;
  id: string;
  pdf: any;
}

const header: string =
  '<header style="display: flex; justify-content: space-between; align-items: center; border: 1px solid black; padding: 10px;"><div style="flex: 1;"><img src="logo.png" alt="Logo" style="max-width: 100%;"></div><div style="flex: 1;"><h1>{title}</h1></div><div style="flex: 1; border: 1px solid black; padding: 10px;"><div style="margin-bottom: 5px;">Date: {date}<span id="date"></span></div><div style="margin-bottom: 5px;">Document ID: {documentId}<span id="document-id"></span></div><div style="margin-bottom: 5px;">Revision Number: {revisionNumber}<span id="revision-number"></span></div><div style="margin-bottom: 5px;">Page Number: Page {pageNumber} of 1<span id="page-number"></span></div></div></header>';

// const content = `<div>
// <h1>Content</h1>
// </div>`;

const Adminside: React.FC = () => {
  const { pdf } = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState<IAdminside>();

  const handleClick = async () => {
    try {
      if (!component) return;

      await updateDoc(doc(db, "PDF", component.id), {
        title: pdf,
        component: text,
      });

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const q = query(
        collection(db, "PDF"),
        where("title", "==", pdf),
        orderBy("title")
      );
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IAdminside, "id">),
      }));

      fetchedData.forEach((data) => {
        setComponent(data);
        setText(data.component);
        setTitle(data.title);
        console.log("component:", data.component);
        console.log("title:", data.title);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [pdf]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    return <Loading />;
  }

  const date = new Date().toLocaleDateString();
  const documentId = "she01";
  const revisionNumber = "1";
  const pageNumber = "1";
  const companyName = "WallMart";
  const siteName = "Value Logistics";

  const replacedText = text
    .replaceAll("{header}", header)
    .replaceAll("{date}", date)
    .replaceAll("{documentId}", documentId)
    .replaceAll("{revisionNumber}", revisionNumber)
    .replaceAll("{pageNumber}", pageNumber)
    .replaceAll("{companyName}", companyName)
    .replaceAll("{siteName}", siteName)
    .replaceAll("{title}", title);

  const downlaodPDF = async () => {
    try {
      setLoading(true);

      console.log("sending request");
      const response = await axios.post(
        "https://ubuconbackend.onrender.com/test",
        {
          pdfFile: replacedText,
          // companyName: companyName,
          // documentId: documentId,
          // revisionNumber: revisionNumber,
          // pageNumber: pageNumber,
          // date: date,
          // siteName: siteName,
        },
        {
          responseType: "arraybuffer", // This sets the response type to binary data (PDF in this case)
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      console.log("pdfblob:", pdfBlob);
      // Create a URL for the Blob to be used by a PDF viewer or download link
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Set the PDF data to the state

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `${title}.pdf`); // Set the desired file name here
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Reset loading state to false
    }
  };

  const handleChange = (value: string | undefined) => {
    setText(value || "");
  };

  return (
    <div className="pdf-section">
      <div className="pdf-header">
        <Button
          varient="primary"
          onClick={handleClick}
          disabled={!{ component }}
        >
          {!component ? "saving..." : "save"}
        </Button>
        <Link to="/">
          <Button varient="secondary">Back</Button>
        </Link>
      </div>
      <div className="pdf-wrapp">
        <div className="pdf-display-area">
          <div
            id="pdf-element"
            style={{
              marginLeft: "30px",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: replacedText }} />
          </div>
        </div>

        <div className="textarea">
        <Editor
          language="html"
          value={text}
          onChange={handleChange}
          theme="vs-dark"
          options={{
            minimap: { enabled: false },
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 18,
            scrollBeyondLastLine: false,
            wordWrap: "on",
            wrappingIndent: "indent",
            automaticLayout: true,
          }}
          height="calc(100vh - 300px)"
        />
        </div>
      </div>
      <div className="buy-now-end-content" onClick={downlaodPDF}>
        <div className="pdf-file">
          <div className="content" style={{ cursor: "pointer" }}>
            <Button varient="secondary">
              <h6 className="text-lg">Download {title}</h6>
            </Button>
          </div>
        </div>
        <div className="download-icon"></div>
      </div>
    </div>
  );
};

export default Adminside;

interface IHtmlComponent {
  isHtml?: any;
}
export const HtmlComponent: React.FC<IHtmlComponent> = ({ isHtml }) => {
  return <div>{isHtml}</div>;
};
