import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore/lite";

// const firebaseConfig = {
//   apiKey: "AIzaSyAbevuJvT5tBQ0NhTzDNYONv0CBzDi8K9U",
//   authDomain: "ubucon-demo.firebaseapp.com",
//   projectId: "ubucon-demo",
//   storageBucket: "ubucon-demo.appspot.com",
//   messagingSenderId: "27957478987",
//   appId: "1:27957478987:web:c8a3a198ac66588c20afce",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAS7C6kcF_16ChJcV64MD-I5mqN2wNl_gE",
  authDomain: "ubucon-e9d05.firebaseapp.com",
  projectId: "ubucon-e9d05",
  storageBucket: "ubucon-e9d05.appspot.com",
  messagingSenderId: "87075944703",
  appId: "1:87075944703:web:11803bd3919231aabd74ca",
  measurementId: "G-YPY5JRWSXQ",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase();
export const storage = getStorage();
export const db = getFirestore(app);
