import React from "react";
import PdfContainer from "./component";
import Layout from "../../components/Layout";
import MoblieHeader from "../../components/MobileHeader";

const Home: React.FC = () => {
  return (
    <Layout>
      <MoblieHeader />
      <PdfContainer />
    </Layout>
  );
};

export default Home;
